import { Modal, Form, Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "./../assets/scss/new-entity-modal.scss";
import Select from "react-select";
import DatePickerCustomInput from "./utils/DatePickerCustomInput";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { LockIcon } from "./Icons";
import { useFormik, getIn } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation, useQuery } from "react-query";
import { services } from "../config";
import { toast } from "react-toastify";
import LoginDialog from "./LoginDialog";
import { useMemo, useState } from "react";
import {
  creditLimitOptions,
  customerTypeOptions,
  didYouKnowOptions,
  nanoid,
} from "../utils/helpers";
import moment from "moment";
import { useAuth } from "../hooks/useAuth";
import { useEffectOnce } from "../utils/hooks";
import CitySelector from "./CitySelector";
import { uniqBy } from "lodash";
import CreatableSelect from "react-select/creatable";
import RsDateRangePicker from "./utils/RsDateRangePicker";
import { useStoreState } from "easy-peasy";

export default function EditCustomerModal({
  showEditCustomerModal,
  setShowEditCustomerModal,
  selectedCustomer,
  setSelectedCustomer,
  refetch,
}) {
  const { backendUrl } = useAuth();
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);
  const generalSettings = useStoreState((state) => state.generalSettings);

  const formik = useFormik({
    initialValues: {
      Cust_ID: String(Date.now()),
      FirstName: "",
      LastName: "",
      MiddleName: "",
      Email: "",
      PhoneNo1: "",
      DOB: moment(),
      DateLog: moment(),
      CompanyAddress: "",
      ContactAddress: "",
      FollowUpComment: "",
      credit: "",
      TransType: "Walk In",
      How_Did_Know: "Walk In",
      Product_Int1: "",
      Product_Int2: "",
      customerLoyalty: false,
      state: "",
      LGA: "",
      creditLimitType: "",
      creditLimitStartDate: new Date(),
      creditLimitEndDate: new Date(),
      Group: "",
      onlineStoreInventorySource: "",
      onlineStoreInventorySourceName: "",
      customerApprovePendingOnlineOrders: false,
    },
    validationSchema: yup.object().shape({
      //  FirstName: yup.string().required(),
      LastName: yup.string().required("Business name is required"),
      //  MiddleName: yup.string().required("Lastname is required"),
      Email: yup.string().email(),
      PhoneNo1: yup.string().required(),
      DOB: yup.string().required(), // moment
      DateLog: yup.string().required(), // moment
      // CompanyAddress: yup.string().required("Company Address is required"),
      // ContactAddress: yup.string().required("Contact Address is required"),
      // FollowUpComment: yup.string(),
      //credit: yup.string(),
      Product_Int1: yup.string(),
      Product_Int2: yup.string(),
      // onlineStoreInventorySource: yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      const { DOB, DateLog } = values;

      if (typeof DOB === "string") {
        formik.setFieldError("DOB", "Invalid date");
      }
      if (typeof DateLog === "string") {
        formik.setFieldError("DateLog", "Invalid date");
      }
      if (typeof DOB === "string" || typeof DateLog === "string") {
        return;
      }

      let { customerApprovePendingOnlineOrders } = values;

      values = {
        ...values,
        customerApprovePendingOnlineOrders: customerApprovePendingOnlineOrders
          ? 1
          : 0,
      };

      submit({
        ...values,
        DOB: DOB.format(),
        DateLog: DateLog.format(),
      });
    },
  });

  useEffectOnce(() => {
    const creditLimitData = selectedCustomer?.Customer_CreditLimit
      ? selectedCustomer?.Customer_CreditLimit
      : {};

    formik.setValues({
      Cust_ID: selectedCustomer.Cust_ID,
      FirstName: selectedCustomer.FirstName,
      LastName: selectedCustomer.LastName,
      MiddleName: selectedCustomer.MiddleName,
      Email: selectedCustomer.Email,
      PhoneNo1: selectedCustomer.PhoneNo1,
      DOB: selectedCustomer.DOB ? moment(selectedCustomer.DOB) : moment(),
      DateLog: selectedCustomer?.Post_Time
        ? moment(selectedCustomer.Post_Time)
        : moment(),
      CompanyAddress: selectedCustomer.CompanyAddress,
      ContactAddress: selectedCustomer.ContactAddress,
      FollowUpComment: selectedCustomer.FollowUpComment,
      credit: selectedCustomer.Customer_CreditLimit?.CreditLimit || 0,
      TransType: selectedCustomer.TransType,
      How_Did_Know: selectedCustomer.How_Did_Know,
      Product_Int1: selectedCustomer?.Product_Int1
        ? selectedCustomer?.Product_Int1
        : "",
      Product_Int2: selectedCustomer?.Product_Int2
        ? selectedCustomer?.Product_Int2
        : "",
      customerLoyalty: false,
      state: selectedCustomer.state,
      LGA: selectedCustomer.LGA,
      creditLimitType: creditLimitData?.creditLimitType,
      creditLimitStartDate: creditLimitData?.creditLimitStartDate
        ? new Date(creditLimitData?.creditLimitStartDate)
        : new Date(),
      creditLimitEndDate: creditLimitData?.creditLimitEndDate
        ? new Date(creditLimitData?.creditLimitEndDate)
        : new Date(),
      Group: selectedCustomer?.Group ? selectedCustomer?.Group : "",
      onlineStoreInventorySource: selectedCustomer?.onlineStoreInventorySource
        ? selectedCustomer?.onlineStoreInventorySource
        : "",
      onlineStoreInventorySourceName: selectedCustomer?.onlineStoreInventorySourceName
        ? selectedCustomer?.onlineStoreInventorySourceName
        : "",

      customerApprovePendingOnlineOrders:
        selectedCustomer?.customerApprovePendingOnlineOrders === true
          ? true
          : false,
    });
  });

  console.log(formik.values);

  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/customers/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.customerTypes = uniqBy(
      [
        ...customerTypeOptions,
        ...data.customerTypes
          .filter((el) => el.TransType)
          .map((el) => ({
            value: el.TransType,
            label: el.TransType,
          })),
      ],
      "value"
    );

    data.groups = uniqBy(
      [
        ...data.groups
          .filter((el) => el.Group)
          .map((el) => ({
            value: el.Group,
            label: el.Group,
          })),
      ],
      "value"
    );

    data.warehouse = data?.warehouse?.map((warehouse) => ({
      ...warehouse,
      value: warehouse.W_name,
      label: warehouse.W_name,
    }));

    const salesInventoryLabel = [
      {
        label: "Sales Inventory",
        label: "Sales Inventory",
        W_ID: "Sales Inventory",
      },
    ];

    data.warehouse = salesInventoryLabel.concat(data.warehouse);
    return data;
  };

  const { data = { customerTypes: [] }, isFetching } = useQuery(
    ["CUSTOMER_SETUP"],
    () => fetchSetUpData(),
    {
      keepPreviousData: true,
    }
  );

  const updateCustomer = async (payload) => {
    let response = await fetch(`${backendUrl}/api/customers/update`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const updateCustomerMutation = useMutation(
    (payload) => updateCustomer(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);

        if (setSelectedCustomer) setSelectedCustomer(data.customer);
        formik.resetForm();
        if (refetch) refetch();
        setShowEditCustomerModal(false);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const submit = (payload) => {
    updateCustomerMutation.mutate(payload);
  };

  const authenticateAdmin = async () => {
    if (await LoginDialog()) {
      setIsAdminAuthenticated(true);
    }
  };

  const onlinestoreSource = useMemo(() => {
    if (data?.warehouse) {
      return data.warehouse.find(
        (el) => el.W_ID === selectedCustomer?.onlineStoreInventorySource
      );
    } else {
      return {
        label: "Sales Inventory",
        value: "Sales Inventory",
        W_ID: "Sales Inventory",
      };
    }
  }, [data?.warehouse]);
  return (
    <Modal
      show={showEditCustomerModal}
      onHide={() => setShowEditCustomerModal(false)}
      dialogClassName="new-entity-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Edit Customer</h1>
          <p>
            Update a customer information by filling in the following forms.
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          onSubmit={formik.handleSubmit}
          className="row pb-2"
          autoComplete="off"
        >
          <div className="col-md-6">
            <h2 className="mb-3 pb-1">Customer Information</h2>

            <Form.Group className="mb-2">
              <Form.Label className="mb-2">Customer/Inquirer ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="xxxxxx"
                name="Cust_ID"
                value={formik.values.Cust_ID}
                style={{ pointerEvents: "none" }}
                onChange={formik.handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label className="mb-2">Customer Type</Form.Label>
              {/* <div className="d-flex gap-3 justify-content-between">
                <Form.Check
                  inline
                  label="Walk In"
                  value="Walk In"
                  name="TransType"
                  type="radio"
                  checked={formik.values.TransType === "Walk In"}
                  onChange={formik.handleChange}
                />

                <Form.Check
                  inline
                  label="Wholesaler"
                  value="Wholesaler"
                  name="TransType"
                  type="radio"
                  checked={formik.values.TransType === "Wholesaler"}
                  onChange={formik.handleChange}
                />

                <Form.Check
                  inline
                  label="Distributor"
                  value="Distributor"
                  name="TransType"
                  type="radio"
                  checked={formik.values.TransType === "Distributor"}
                  onChange={formik.handleChange}
                />
              </div> */}

              <Select
                classNamePrefix={`form-select`}
                options={data.customerTypes}
                value={data?.customerTypes?.find(
                  (el) => el.value === formik.values.TransType
                )}
                onChange={({ value }) =>
                  formik.setFieldValue("TransType", value)
                }
                isLoading={isFetching}
              />
            </Form.Group>

            <Form.Group className="mb-3 pb-2">
              <Form.Label className="mb-1">Business Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter business name"
                name="LastName"
                value={formik.values.LastName}
                onChange={formik.handleChange}
                isInvalid={formik.touched.LastName && !!formik.errors.LastName}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.LastName}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter first name"
                  name="FirstName"
                  value={formik.values.FirstName}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.FirstName && !!formik.errors.FirstName
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.FirstName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter last name"
                  name="MiddleName"
                  value={formik.values.MiddleName}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.MiddleName && !!formik.errors.MiddleName
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.MiddleName}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Email Address</Form.Label>
                <Form.Control
                  className=""
                  type="email"
                  placeholder="Enter email"
                  name="Email"
                  value={formik.values.Email}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.Email && !!formik.errors.Email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Phone Number</Form.Label>
                <Form.Control
                  className=""
                  type="tel"
                  placeholder="(555) xxxx xxxx"
                  name="PhoneNo1"
                  value={formik.values.PhoneNo1}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.PhoneNo1 && !!formik.errors.PhoneNo1
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.PhoneNo1}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1" htmlFor="DOB">
                Date of Birth
              </Form.Label>
              <Datetime
                dateFormat="MMM DD"
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside={true}
                name="DOB"
                inputProps={{
                  className: `date-input form-control ${
                    formik.touched.DOB && !!formik.errors.DOB
                      ? "is-invalid"
                      : ""
                  }`,
                  placeholder: "Select date",
                  readOnly: true,
                }}
                value={formik.values.DOB}
                onChange={(date) => {
                  formik.setFieldValue("DOB", date, true);
                }}
                onBlur={() => formik.setFieldTouched("BOB", true)}
              />
              {formik.touched.DOB && !!formik.errors.DOB ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.DOB}
                </span>
              ) : null}
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Customer Address</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter your customer address"
                name="ContactAddress"
                rows={5}
                value={formik.values.ContactAddress}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.ContactAddress &&
                  !!formik.errors.ContactAddress
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.ContactAddress}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="">
              <Form.Label className="mb-1">Company's Address</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter company's address"
                name="CompanyAddress"
                rows={5}
                value={formik.values.CompanyAddress}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.CompanyAddress &&
                  !!formik.errors.CompanyAddress
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.CompanyAddress}
              </Form.Control.Feedback>
            </Form.Group>

            <CitySelector formik={formik} />
          </div>

          <div className="col-md-6 d-flex flex-column justify-content-between">
            <div>
              <h2 className="mt-4 mt-md-0 mb-3  pb-1">Other Information</h2>

              <div className="row">
                <Form.Group className="col-6 mb-3 pb-2">
                  <Form.Label className="mb-1">Customer Log Date</Form.Label>
                  <Datetime
                    dateFormat="MMM DD, YYYY"
                    timeFormat={false}
                    closeOnSelect={true}
                    closeOnClickOutside={true}
                    name="DateLog"
                    inputProps={{
                      className: `date-input form-control ${
                        formik.touched.DateLog && !!formik.errors.DateLog
                          ? "is-invalid"
                          : ""
                      }`,
                      placeholder: "Select date",
                      readOnly: true,
                    }}
                    value={formik.values.DateLog}
                    onChange={(date) => {
                      formik.setFieldValue("DateLog", date, true);
                    }}
                    onBlur={() => formik.setFieldTouched("DateLog", true)}
                  />
                  {formik.touched.DateLog && !!formik.errors.DateLog ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.DateLog}
                    </span>
                  ) : null}
                </Form.Group>
                <Form.Group className="col-6 mb-3 pb-2">
                  <Form.Label className="mb-1 text-nowrap">
                    How did Know About Us?
                  </Form.Label>
                  <Select
                    classNamePrefix={"form-select"}
                    isSearchable={false}
                    value={didYouKnowOptions.find(
                      (el) => el.value === formik.values.How_Did_Know
                    )}
                    onChange={({ value }) =>
                      formik.setFieldValue("How_Did_Know", value)
                    }
                    options={didYouKnowOptions}
                  />
                  <Form.Control.Feedback type="invalid" />
                </Form.Group>
              </div>

              {/*  <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">If Other Specify</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter last name"
                  name="How_Did_Know"
                  value={formik.values.How_Did_Know}
                  onChange={formik.handleChange}
                />
              </Form.Group> */}

              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Product of Interest</Form.Label>
                <Form.Control
                  className="mb-3"
                  type="text"
                  placeholder="Enter Product of Interest"
                  name="Product_Int1"
                  value={formik.values.Product_Int1}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.Product_Int1 && !!formik.errors.Product_Int1
                  }
                />

                <Form.Control
                  type="text"
                  placeholder="Enter Product of Interest 2"
                  name="Product_Int2"
                  value={formik.values.Product_Int2}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.Product_Int2 && !!formik.errors.Product_Int2
                  }
                />
              </Form.Group>

              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">
                  Follow Up Comment (optional)
                </Form.Label>
                <Form.Control
                  className=""
                  as="textarea"
                  placeholder=""
                  name="FollowUpComment"
                  value={formik.values.FollowUpComment}
                  onChange={formik.handleChange}
                  rows={5}
                />
              </Form.Group>

              <Form.Check
                type="switch"
                label="Activate Customer Loyalty"
                name="customerLoyalty"
                className="custom-form-check mb-3"
                checked={formik.values.customerLoyalty}
                onChange={formik.handleChange}
              />

              <div className="customer-credit">
                <h2 className="mb-1 pb-1"> Customer Credit Limit</h2>

                {!isAdminAuthenticated && (
                  <Button
                    onClick={() => authenticateAdmin()}
                    variant=""
                    type="button"
                    className="text-primary mb-3 pb-1 d-flex align-items-center gap-2"
                  >
                    <LockIcon />

                    <span style={{ opacity: 0.65 }}>Enable Credit Limit</span>
                  </Button>
                )}

                {isAdminAuthenticated && (
                  <>
                    <Form.Group className=" mb-3">
                      <Form.Label className="mb-1">Amount</Form.Label>
                      <CurrencyCustomInput
                        disabled={!isAdminAuthenticated}
                        placeholder="0.00"
                        name="credit"
                        value={formik.values.credit}
                        onValueChange={(value, name) =>
                          formik.setFieldValue(name, value)
                        }
                      />
                    </Form.Group>

                    <Form.Group className=" mb-3">
                      <Form.Label className="mb-1">Type</Form.Label>
                      <Select
                        classNamePrefix={"form-select"}
                        options={creditLimitOptions}
                        value={creditLimitOptions.find(
                          (el) => el.value === formik.values.creditLimitType
                        )}
                        onChange={({ value }) =>
                          formik.setFieldValue("creditLimitType", value)
                        }
                      />
                    </Form.Group>

                    {formik.values.creditLimitType === "Date range" && (
                      <Form.Group className=" mb-3">
                        <Form.Label className="mb-1 w-100">
                          Date Range
                        </Form.Label>
                        <RsDateRangePicker
                          placement="topStart"
                          value={
                            formik.values.creditLimitStartDate &&
                            formik.values.creditLimitEndDate
                              ? [
                                  formik.values.creditLimitStartDate,
                                  formik.values.creditLimitEndDate,
                                ]
                              : []
                          }
                          onClean={() => {
                            formik.setFieldValue("creditLimitStartDate", null);
                            formik.setFieldValue("creditLimitEndDate", null);
                          }}
                          onOk={(date) => {
                            formik.setFieldValue(
                              "creditLimitStartDate",
                              date[0]
                            );
                            formik.setFieldValue("creditLimitEndDate", date[1]);
                          }}
                        />
                      </Form.Group>
                    )}
                  </>
                )}
              </div>

              <hr />

              <Form.Group className="mb-2">
                <Form.Label className="mb-2">Customer Group</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  options={data.groups}
                  value={data?.groups?.find(
                    (el) => el.value === formik.values.Group
                  )}
                  onChange={({ value }) => formik.setFieldValue("Group", value)}
                  isLoading={isFetching}
                />
              </Form.Group>
              {generalSettings?.store && (
                <div>
                  <Form.Group>
                    <Form.Label>Online Store Inventory</Form.Label>
                    <Select
                      isSearchable
                      key={JSON.stringify(onlinestoreSource)}
                      defaultValue={onlinestoreSource}
                      name="onlineStoreInventorySource"
                      options={data.warehouse || []}
                      onChange={(select) => {
                        formik.setFieldValue(
                          "onlineStoreInventorySource",
                          select.W_ID
                        );
                        formik.setFieldValue(
                          "onlineStoreInventorySourceName",
                          select.label
                        );
                      }}
                      isInvalid={
                        formik.touched.onlineStoreInventorySource &&
                        !!formik.errors.onlineStoreInventorySource
                      }
                      // value={data.warehouse.find(
                      //   (el) => el.W_ID === formik.values.onlineStoreInventorySource
                      // )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.onlineStoreInventorySource}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className=" my-3 pb-2 ">
                    <Form.Check
                      inline
                      id="customerApprovePendingOnlineOrders"
                      label="Enable Customers to Approve and Close Out Pending Online Orders"
                      name="customerApprovePendingOnlineOrders"
                      type={"checkbox"}
                      checked={formik.values.customerApprovePendingOnlineOrders}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                </div>
              )}
            </div>

            <Button
              variant="primary"
              type="submit"
              className="w-100 submit-btn mt-3"
              disabled={updateCustomerMutation.isLoading}
            >
              {updateCustomerMutation.isLoading
                ? "Please wait..."
                : "Save Changes"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
