import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Modal, Table, Form, Button, Dropdown } from "react-bootstrap";
// import "./../assets/scss/customer-select-modal.scss";
import useDebounce, {
  useEffectOnce,
  useSingleAndDoubleClick,
} from "../../utils/hooks";
import { appSettings, services } from "../../config";
import queryString from "query-string";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { queryActions } from "../../utils/reactQueryActions";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import {
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  scrollToTop,
  tonsToPcs,
  toTonsOrPcs,
} from "../../utils/helpers";
import currency from "currency.js";
import Datetime from "react-datetime";
import { format, startOfMonth, parse, differenceInWeeks } from "date-fns";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import {
  BookIcon,
  CashSelectIcon,
  CheckCircleIcon,
  ChequeSelectIcon,
  DeleteIcon,
  DropdownEyeIcon,
  EditIcon,
  FileListIcon,
  RecieptIcon,
} from "../Icons";
import { toast } from "react-toastify";
import LoginDialog from "../LoginDialog";
import ConfirmDialog from "../ConfirmDialogue";
import CurrencyInput from "react-currency-input-field";
import BatchQuantityDialog from "../BatchQuantityDialog";
import { isEmpty } from "lodash";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "../utils/ModalLoader";
import ConvertQuantity from "../utils/ConvertQuantity";
import { Link } from "react-router-dom";
import QuantityAdjustmentModal from "../QuantityAdjustmentModal";
import CachedIcon from "mdi-react/CachedIcon";
import CloseIcon from "mdi-react/CloseIcon";
import moment from "moment";
import OutsourceBatchToVendorModal from "../OutsourceBatchToVendorModal";
import BatchConsumptionModal from "./BatchConsumtionModal";
import BatchProductionReportModal from "./BatchProductionReportModal";
import SerialItemModal from "./SerialItemModal";
import EditBatchHistoryModal from "./EditBatchHistoryModal";

export default function BatchesModal({
  selectedItem,
  setSelectedItem,
  setShowBatchesModal,
}) {
  const initialFilterParams = {
    Bar_Code: selectedItem?.Bar_Code,
    RegDate: "",
    InvoiceNo: "",
    q: "",
  };
  const queryClient = useQueryClient();
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl, isIronRod } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [showQuantityAdjustModal, setShowQuantityAdjustModal] = useState(false);
  const [
    showOutsourceBatchToVendorModal,
    setShowOutsourceBatchToVendorModal,
  ] = useState(false);
  const [selectedBatchItem, setSelectedBatchItem] = useState();
  const [showBatchConsumptionModal, setShowBatchConsumptionModal] = useState(
    false
  );
  const [showProductionReportModal, setShowProductionReportModal] = useState(
    false
  );

  const [showSerialNumberModal, setShowSerialNumberModal] = useState(false);

  const [selectedBatch, setSelectedBatch] = useState({});
  const [showBatchHistoryModal, setShowBatchHistoryModal] = useState(false);
  const [batchName, setBatchName] = useState("");

  const generalSettings = useStoreState((state) => state.generalSettings);

  const debouncedqueryParams = useDebounce(queryParams, 800);

  const getBatches = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/items/batches?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const { data = { batches: [] }, refetch } = useQuery(
    ["BATCHES", debouncedqueryParams],
    () => getBatches(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const deleteBatch = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/delete-batch`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteMutation = useMutation((payload) => deleteBatch(payload), {
    onSuccess: ({ message, data: { batch } }) => {
      toast.success(message);
      queryClient.setQueryData(["BATCHES", queryParams], (data) => {
        data.batches = data.batches.filter(
          (el) => el.Bar_Code !== batch.Bar_Code
        );
        return data;
      });
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const updateBatch = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/update-batch`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...payload,
      }),
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const updateBatchMutation = useMutation((payload) => updateBatch(payload), {
    onSuccess: () => {
      toast.success(`Success`);
      refetch();
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const handleDelete = async (batch) => {
    if (await LoginDialog()) {
      if (
        await ConfirmDialog({
          title: "Delete Batch",
          description:
            "Are you sure you want to delete this Batch from your inventory",
        })
      ) {
        deleteMutation.mutate(batch);
      }
    }
  };

  const handleEdit = async (batch) => {
    if (await LoginDialog()) {
      const tonsAndPcs = qtyFormat(
        batch.Quantity,
        data?.item?.Item_Desc,
        itemMeasurements
      );
      const Tons = tonsAndPcs.split("-")[0];
      const Pieces = tonsAndPcs.split("-")[1];
      const formatType = tonsAndPcs.split("-")[2];

      const values = await BatchQuantityDialog({
        batch,
        Tons,
        Pieces,
        formatType,
        ExpireDate: batch.ExpireDate,
        ManDate: batch.ManDate,
      });

      if (!isEmpty(values)) {
        let UnitCost = Number(
          currency(values.UnitCost, { symbol: "", separator: "" }).format()
        );

        let UnitPrice = Number(
          currency(values.UnitPrice, { symbol: "", separator: "" }).format()
        );

        let VAT = Number(
          currency(values.VAT, { symbol: "", separator: "" }).format()
        );

        let oldQuantity = Number(
          currency(batch.Quantity, { symbol: "", separator: "" }).format()
        );

        const tons = values?.Tons
          ? tonsToPcs(values?.Tons, data?.item?.Item_Desc, itemMeasurements)
          : 0;

        let Quantity = Number(
          currency(tons, { symbol: "", separator: "" })
            .add(values.Pieces)
            .format()
        );

        updateBatchMutation.mutate({
          batch,
          UnitCost,
          UnitPrice,
          Quantity,
          oldQuantity,
          VAT,
          ExpireDate: values?.ExpireDate,
          ManDate: values?.ManDate,
          Batch_Name: values?.Batch_Name,
          StorageLocation: values?.StorageLocation,
        });
      }
    }
  };

  const handleQuantityAdjustment = (batch) => {
    setSelectedBatchItem(batch);
    setShowQuantityAdjustModal(true);
  };

  const handleOutsource = (batch) => {
    setSelectedBatchItem(batch);
    setShowOutsourceBatchToVendorModal(true);
  };

  const quarantineBatch = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/items/quarantine-from-batch`,
      {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          ...payload,
        }),
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const quarantineBatchMutation = useMutation(
    (payload) => quarantineBatch(payload),
    {
      onSuccess: () => {
        toast.success(`Success`);
        refetch();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const quarantine = async (batch) => {
    if (await LoginDialog()) {
      if (
        await ConfirmDialog({
          title: "Quarantine Batch",
          description: "Are you sure you want to quarantine this Batch",
        })
      ) {
        quarantineBatchMutation.mutate({ batch });
      }
    }
  };

  return (
    <>
      <Modal
        show={true}
        onHide={() => {
          setSelectedItem(null);
          setShowBatchesModal(false);
        }}
        dialogClassName="requisition-details-modal approve-modal"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="h5">Batch Details</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4 d-flex flex-column pt-0">
          <div className="search-area d-flex justify-content-between align-items-center gap-3 w-100 px-3 mb-4 h6">
            <h2>
              {selectedItem?.Item_Name} / {selectedItem?.Bar_Code}{" "}
              <button
                title="Refresh"
                onClick={() => refetch()}
                className="btn text-primary"
              >
                <CachedIcon />
              </button>
            </h2>

            <Form.Group>
              <Form.Check
                type="switch"
                label="Show All"
                name="showAll"
                className="ga-form-check fs-6"
                checked={queryParams.showAll}
                onChange={handleSearchQueryChange}
              />
            </Form.Group>
          </div>

          <div className="col-6 d-flex gap-3 align-items-center pe-3 mb-3">
            <div className="global-search-area w-75">
              <MagnifyIcon />
              <input
                className="form-control search-input"
                name="q"
                value={queryParams.q}
                onChange={(e) =>
                  setQueryParams({
                    ...queryParams,
                    page: 1,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder={`Search Invoice number, ${
                  generalSettings.Batch_Name
                }`}
                autoFocus
                autoComplete="off"
                type={"text"}
              />
            </div>

            <Datetime
              dateFormat="MMM DD, YYYY"
              timeFormat={false}
              closeOnSelect={true}
              closeOnClickOutside={true}
              inputProps={{
                className: `date-input form-control`,
                placeholder: "Select Reg Date",
                readOnly: true,
              }}
              value={queryParams.RegDate}
              onChange={(date) => {
                setQueryParams({
                  ...queryParams,
                  RegDate: date,
                });
              }}
            />
          </div>

          <div className="content px-1 pb-4 pe-2 flex-grow-1">
            <Table borderless responsive striped className="product-table">
              <thead className="sticky border-bottom">
                <tr>
                  <th />
                  <th>Batch</th>
                  {generalSettings.canAddBatchName && (
                    <th>{generalSettings.Batch_Name}</th>
                  )}
                  <th>Vendor Invoice No</th>
                  <th>Storage Location</th>
                  {generalSettings.isFarm && <th>Parent Batch</th>}
                  <th>Unit Cost</th>
                  {generalSettings.isFarm && <th>Initial Quantity</th>}
                  <th>Quantity</th>
                  <th>Sub-Total</th>
                  <th>Unit Price</th>
                  {selectedItem?.Product_Model === "Tons" ? (
                    <th>Unit Price (Pcs)</th>
                  ) : null}
                  <th>VAT</th>
                  <th>Reg Date</th>
                  <th>Man Date</th>
                  <th>Age</th>
                  <th>Exp Date</th>
                  <th>Driver Name</th>
                  <th>Phone Number</th>
                  <th>Truck Number</th>
                  <th>Delivery Date</th>
                </tr>
              </thead>

              <tbody className="blue-hover">
                {data?.batches &&
                  data.batches.map((el, index) => (
                    <tr key={index} className={`p-cursor`}>
                      <td>
                        <Dropdown flip={true} alignRight drop="end">
                          <Dropdown.Toggle
                            variant=""
                            className="bg-white border-0 px-0"
                            bsPrefix="print more"
                          >
                            <DotsVerticalIcon />
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            popperConfig={{
                              strategy: "fixed",
                            }}
                            renderOnMount
                            className="dropdown-with-icons"
                          >
                            {el?.InvoiceNo && (
                              <>
                                <Dropdown.Item as="div">
                                  <Link
                                    to={`/reports/purchase-history?NoDate=${true}&invoiceNumber=${
                                      el?.InvoiceNo
                                    }`}
                                  >
                                    <DropdownEyeIcon /> Open in Purchase History
                                  </Link>
                                </Dropdown.Item>{" "}
                              </>
                            )}
                            <Dropdown.Item
                              as="button"
                              onClick={() => {
                                setShowSerialNumberModal(true);
                                setSelectedBatch(el);
                              }}
                            >
                              <DropdownEyeIcon /> Open Serial Number
                            </Dropdown.Item>

                            <Dropdown.Item
                              as="button"
                              onClick={() => handleQuantityAdjustment(el)}
                            >
                              <EditIcon /> Quantity Adjustment
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="button"
                              onClick={() => handleEdit(el)}
                            >
                              <EditIcon /> Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="button"
                              onClick={() => {
                                setBatchName(`${el?.Bar_Code}`);
                                setShowBatchHistoryModal(true);
                              }}
                            >
                              <BookIcon /> Adjustment History
                            </Dropdown.Item>
                            {el?.InvoiceNo && (
                              <>
                                <Dropdown.Item
                                  as="button"
                                  onClick={() => quarantine(el)}
                                >
                                  <RecieptIcon /> Quarantine
                                </Dropdown.Item>
                              </>
                            )}
                            {generalSettings.isFarm && (
                              <>
                                {/*  <Dropdown.Item
                                  as="button"
                                  onClick={() => handleOutsource(el)}
                                >
                                  <CashSelectIcon /> Outsource
                                </Dropdown.Item> */}
                                <Dropdown.Item
                                  as="button"
                                  onClick={() => {
                                    setSelectedBatchItem(el);
                                    setShowBatchConsumptionModal(true);
                                  }}
                                  flip={true}
                                >
                                  <ChequeSelectIcon /> Consumption
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  onClick={() => {
                                    setSelectedBatchItem(el);
                                    setShowProductionReportModal(true);
                                  }}
                                  flip={true}
                                >
                                  <ChequeSelectIcon /> Production Report
                                </Dropdown.Item>
                              </>
                            )}
                            <Dropdown.Item
                              as="button"
                              onClick={() => handleDelete(el)}
                              flip={true}
                            >
                              <DeleteIcon /> Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td>
                        {el.Bar_Code ? el.Bar_Code.split("-").pop() : "..."}
                      </td>
                      {generalSettings.canAddBatchName && (
                        <td>{el.Batch_Name}</td>
                      )}
                      <td>
                        {el?.InvoiceNo ? (
                          <Link
                            to={`/reports/purchase-history?NoDate=${true}&invoiceNumber=${
                              el?.InvoiceNo
                            }`}
                          >
                            {el?.InvoiceNo}
                          </Link>
                        ) : (
                          "..."
                        )}
                      </td>
                      <td>
                        {el?.storageLocation?.name || el?.StorageLocation?.name}
                      </td>
                      {generalSettings.isFarm && (
                        <td>
                          {`${el.parentBatchBarCode ||
                            "..."} / ${el.parentBatchItemName ||
                            "..."} / ${el.parentBatchName || "..."}`}
                        </td>
                      )}
                      <td>
                        {currency(el.UnitCost, {
                          symbol: "",
                        }).format()}
                      </td>

                      {generalSettings.isFarm && (
                        <td className="text-nowrap">
                          <ConvertQuantity
                            quantity={el.originalQuantity}
                            desc={data?.item?.Item_Desc}
                          />
                        </td>
                      )}

                      <td className="text-nowrap">
                        <ConvertQuantity
                          quantity={el.Quantity}
                          desc={data?.item?.Item_Desc}
                        />
                      </td>

                      <td>
                        {currency(el.subTotal, {
                          symbol: "",
                        }).format()}
                      </td>
                      <td>
                        {currency(el.UnitPrice, {
                          symbol: "",
                        }).format()}
                      </td>
                      {selectedItem?.Product_Model === "Tons" ? (
                        <td>
                          {currency(el.VAT, {
                            symbol: "",
                          }).format()}
                        </td>
                      ) : null}
                      <td>
                        {currency(el.itemVAT, {
                          symbol: "",
                        }).format()}
                      </td>
                      <td className="text-nowrap">
                        {el.RegDate
                          ? format(new Date(el.RegDate), "dd MMM, yyyy")
                          : "..."}
                      </td>
                      <td className="text-nowrap">
                        {el.ManDate
                          ? format(new Date(el.ManDate), "dd MMM, yyyy")
                          : "..."}
                      </td>
                      <td className="text-nowrap">
                        {el.ManDate
                          ? differenceInWeeks(new Date(), new Date(el.ManDate))
                          : "..."}{" "}
                        Weeks
                      </td>
                      <td className="text-nowrap">
                        {el.ExpireDate
                          ? format(new Date(el.ExpireDate), "dd MMM, yyyy")
                          : "..."}
                      </td>
                      <td>{el?.DriverName}</td>
                      <td>{el?.DriverPhoneNumber}</td>
                      <td>{el?.TruckNumber}</td>
                      <td>
                        {el?.DeliveryDate
                          ? format(new Date(el.DeliveryDate), "dd MMM, yyyy")
                          : "..."}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>

          <div className="d-flex justify-content-between px-3 align-items-center pagination">
            <p className="m-0">
              Showing {data?.startIndex + 1} to{" "}
              {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
              {data.count} entries
            </p>

            <p className="fw-6">
              <ConvertQuantity
                quantity={data?.totalQty}
                desc={data?.item?.Item_Desc}
              />
            </p>

            <p className="fw-6">
              {currency(data?.valueInStock, { symbol: "" }).format()}
            </p>

            <ReactPaginate
              {...paginationOptions}
              pageCount={Math.ceil(data.count / queryParams.limit)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={0}
              onPageChange={({ selected }) => {
                scrollToTop();
                setQueryParams({
                  ...queryParams,
                  page: selected + 1,
                });
              }}
              forcePage={queryParams.page - 1}
            />
          </div>
        </Modal.Body>

        <ModalLoader
          show={
            updateBatchMutation.isLoading || quarantineBatchMutation.isLoading
          }
        />
      </Modal>

      {showSerialNumberModal && (
        <SerialItemModal
          show={showSerialNumberModal}
          selectedItem={selectedBatch}
          setSelectedItem={setSelectedBatch}
          setShowBatchesModal={setShowSerialNumberModal}
        />
      )}

      {selectedBatchItem && showQuantityAdjustModal ? (
        <QuantityAdjustmentModal
          showQuantityAdjustModal={showQuantityAdjustModal}
          setShowQuantityAdjustModal={setShowQuantityAdjustModal}
          refetch={refetch}
          selectedBatchItem={selectedBatchItem}
          setSelectedBatchItem={setSelectedBatchItem}
          selectedItem={selectedItem}
        />
      ) : null}

      {selectedBatchItem && showOutsourceBatchToVendorModal ? (
        <OutsourceBatchToVendorModal
          showOutsourceBatchToVendorModal={showOutsourceBatchToVendorModal}
          setShowOutsourceBatchToVendorModal={
            setShowOutsourceBatchToVendorModal
          }
          refetch={refetch}
          selectedBatchItem={selectedBatchItem}
          setSelectedBatchItem={setSelectedBatchItem}
          selectedItem={selectedItem}
        />
      ) : null}

      {selectedBatchItem && showBatchConsumptionModal ? (
        <BatchConsumptionModal
          showBatchConsumptionModal={showBatchConsumptionModal}
          setShowBatchConsumptionModal={setShowBatchConsumptionModal}
          refetch={refetch}
          selectedBatchItem={selectedBatchItem}
          setSelectedBatchItem={setSelectedBatchItem}
          selectedItem={selectedItem}
        />
      ) : null}

      {selectedBatchItem && showProductionReportModal ? (
        <BatchProductionReportModal
          showProductionReportModal={showProductionReportModal}
          setShowProductionReportModal={setShowProductionReportModal}
          refetch={refetch}
          selectedBatchItem={selectedBatchItem}
          setSelectedBatchItem={setSelectedBatchItem}
          selectedItem={selectedItem}
        />
      ) : null}

      {showBatchHistoryModal && (
        <EditBatchHistoryModal
          showBatchHistoryModal={showBatchHistoryModal}
          setShowBatchHistoryModal={setShowBatchHistoryModal}
          setBatchName={setBatchName}
          batchName={batchName}
          title={`${selectedItem?.Item_Name} / ${selectedItem?.Bar_Code}`}
        />
      )}
    </>
  );
}
