import { Dropdown, Table, Form, Button } from "react-bootstrap";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  ChartPieSliceTitleIcon,
  FilterCollapseIcon,
  ExchangeFunds,
  DropdownEyeIcon,
  DropdownCheckIcon,
  DropdownCloseIcon,
  LinkVariantIcon,
  EditIcon,
  DeleteIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useIsAdmin,
  useIsCashier,
  useIsSalesManager,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useMutation, useQuery, useQueryClient } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  copyText,
  customerFullName,
  defaultSelectValue,
  paginationOptions,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import PageHeader from "../PageHeader";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Outlet, useNavigate } from "react-router-dom";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { LinkWithQuery as Link } from "../utils/LinkWithQuery";
import { toast } from "react-toastify";
import eventBus from "../../utils/EventBus";
import { filter, isEmpty, uniqBy } from "lodash";
import { useAuth } from "../../hooks/useAuth";
import ConfirmDialog, {
  ConfirmApproveAndProcessAllDialogDialog,
  ConfirmApproveAndProcessDialogDialog,
} from "../ConfirmDialogue";
import ModalLoader from "../utils/ModalLoader";
import NoTableItem from "../utils/NoTableItem";
import ChangeStoreOrderStatusDialog from "../ChangeStoreOrderPaymentStatus";
import { IsPrivileged } from "../DisplayChildElement";
import ViewOrdersModal from "../CustomerDetails/ViewOrdersModal";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";

import { toaster } from "rsuite";

export default function ApprovalIndex({ sentBy = "", status = "Pending" }) {
  //  useScrollTop();
  const { backendUrl } = useAuth();
  const queryClient = useQueryClient();
  const initialFilterParams = { q: "", page: 1, limit: 40 };

  const isCashier = useIsCashier();
  const isAdmin = useIsAdmin();
  const isSalesManager = useIsSalesManager();
  const navigate = useNavigate();
  const [orderTransactionID, setOrderTransactionID] = useState();
  const [queryParams, setQueryParams] = useQueryParams({});
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);
  const [selectedTransID, setTransID] = useState([]);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [, setShowMoreSummary] = useState(true);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchPendingPermits = async (queryParams, sentBy) => {
    // console.log(queryParams);
    let response = await fetch(
      `${backendUrl}/api/permits?&${queryString.stringify({
        ...queryParams,
        sentBy,
        ...(status ? { status } : {}),
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    data.TotalAmount = data?.totalValues?.reduce(
      (acc, item) => acc + Number(item?.totalValue),
      0
    );
    // data.AmountPaid = data?.permits?.reduce(
    //   (acc, item) => acc + Number(item?.AmountPaid),
    //   0
    // );

    data.warehouse = data?.warehouseName?.map((warehouse) => ({
      ...warehouse,
      value: warehouse.W_name,
      label: warehouse.W_name,
      source: warehouse.W_ID,
    }));

    // const salesInventoryLabel = [
    //   {
    //     label: "Sales Inventory",
    //     value: "Sales Inventory",
    //     source: "Sales Inventory",
    //   },
    // ];

    return data;
  };

  const {
    data = { pendingPermits: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [reportActions.PENDING_PERMITS, queryParams, sentBy],
    () => fetchPendingPermits(queryParams, sentBy),
    {
      keepPreviousData: true,
      staleTime: 0,
    }
  );

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
    });
  };

  const viewDetails = (el) => {
    el.TransactionID && navigate(`/approval/details/${el.TransactionID}`);
  };

  const updateAllPermit = async (payload) => {
    let response = await fetch(`${backendUrl}/api/permits/change-status-all`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const updatePermitsMutation = useMutation(
    (payload) => updateAllPermit(payload),
    {
      onSuccess: ({ status, transactionIds }) => {
        queryClient.setQueryData(
          [reportActions.PENDING_PERMITS, queryParams, sentBy],
          (data) => {
            /* if (status === "Approved") {
              data.pendingPermits = data.pendingPermits.map((el) => {
                if (transactionIds.includes(el.TransactionID)) {
                  return {
                    ...el,
                    Status: status,
                  };
                }
              });
            } else if (status === "Disapproved") { */
            data.pendingPermits = data.pendingPermits.filter(
              (el) => !transactionIds.includes(el.TransactionID)
            );
            //  }
            return data;
          }
        );
        toast.success(`Success`);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const deletePermitApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/permits/delete`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const deletePermitMutation = useMutation(
    (payload) => deletePermitApi(payload),
    {
      onSuccess: ({ status, transactionIds }) => {
        queryClient.setQueryData(
          [reportActions.PENDING_PERMITS, queryParams, sentBy],
          (data) => {
            data.pendingPermits = data.pendingPermits.filter(
              (el) => !transactionIds.includes(el.TransactionID)
            );
            return data;
          }
        );
        toast.success(`Success`);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const approveAll = async () => {
    if (
      await ConfirmDialog({
        title: "Approve all",
        description: "Are you sure, you want to approve all",
      })
    ) {
      const transactionIds = data.pendingPermits.map((el) => el.TransactionID);
      updatePermitsMutation.mutate({
        status: "Approved",
        transactionIds,
      });
    }
  };

  const disapproveAll = async () => {
    if (
      await ConfirmDialog({
        title: "Disapprove all",
        description: "Are you sure, you want to disapprove all",
      })
    ) {
      const transactionIds = data.pendingPermits.map((el) => el.TransactionID);
      updatePermitsMutation.mutate({
        status: "Disapproved",
        transactionIds,
      });
    }
  };

  const approve = async (el) => {
    if (
      await ConfirmDialog({
        title: "Approve",
        description: "Are you sure, you want to approve",
      })
    ) {
      updatePermitsMutation.mutate({
        status: "Approved",
        transactionIds: [el.TransactionID],
      });
    }
  };

  const disapprove = async (el) => {
    if (
      await ConfirmDialog({
        title: "Disapprove",
        description: "Are you sure, you want to disapprove",
      })
    ) {
      updatePermitsMutation.mutate({
        status: "Disapproved",
        transactionIds: [el.TransactionID],
      });
    }
  };

  const deletePermit = async (el) => {
    if (
      await ConfirmDialog({
        title: "Delete",
        description: "Are you sure, you want to delete",
      })
    ) {
      deletePermitMutation.mutate({
        transactionIds: [el.TransactionID],
      });
    }
  };

  const changePaymentStatus = async (el) => {
    await ChangeStoreOrderStatusDialog({
      TransactionID: el.TransactionID,
      paymentStatus: el.paymentStatus,
      refetch,
      customerName:
        el?.customer?.LastName === "Walk-In" && el?.ShipTo
          ? `${el?.ShipTo} (Walk-In)`
          : customerFullName(el.customer),
      oldPaymentStatus: el.paymentStatus,
    });
  };

  eventBus.useCustomEventListener("FETCH_PENDING_PERMITS", refetch);

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const handleApproveAndProcess = async (transID) => {
    // console.log(transID);
    await ConfirmApproveAndProcessDialogDialog({
      transactionID: transID,
      title: "Approve and Process",
      description:
        "Are you sure, you want to approve and process Transaction ?",
      choice: true,
      url: backendUrl,
      refetch,
      // summary: transID,
    });
  };

  function handleItemCheck({ id }) {
    const findTransID = selectedTransID.find((trans) => trans === id);
    if (findTransID) {
      let remaininID = selectedTransID.filter((trans) => trans !== id);
      setTransID(remaininID);
    } else {
      setTransID((prev) => [...prev, id]);
    }
  }

  async function handleSelectApproveAndProcessAll(transIDs) {
    if (selectedTransID?.length < 1) {
      toast.error("You have to select at least one order");
      return;
    }

    const status = await ConfirmApproveAndProcessAllDialogDialog({
      transactionID: transIDs,
      title: "Approve and Process Selected Orders",
      description:
        "Are you sure, you want to approve and process transactions?",
      choice: true,
      url: backendUrl,
      refetch,
      // summary: transID,
    });

    if (status) {
    }

    setTransID([]);
  }

  function handleSelectAll(transID) {
    const allTransID = transID.map((id) => id.TransactionID);
    if (selectedTransID?.length < allTransID?.length) {
      setTransID(allTransID);
    } else {
      setTransID([]);
    }
  }

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  return (
    <IsPrivileged
      roleName={[
        "Approve Pending Invoice",
        "Pending Online Orders",
        "Pending Online Quotes",
      ]}
    >
      <main className="inner-page-outlet">
        <PageHeader
          name={
            sentBy === "Store"
              ? status === "Pending"
                ? "Pending Online Orders"
                : "Pending Online Quotes"
              : "Approve Pending Invoice"
          }
          description="Your current pending"
          icon={<ChartPieSliceTitleIcon />}
        />

        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className="content-body">
                <header className="flex-wrap gap-3">
                  <div className="d-flex flex-grow-1  gap-3">
                    <div className="global-search-area col col-md-6">
                      <MagnifyIcon />
                      <Form.Control
                        id="queryParams-q"
                        className=""
                        name="TransactionID"
                        value={filterParams.TransactionID}
                        onChange={(e) => handleSearchQueryChange(e)}
                        placeholder="Search Transaction ID..."
                      />
                    </div>

                    <RsDateRangePicker
                      placement="bottomEnd"
                      defaultValue={
                        queryParams.startDate && queryParams.endDate
                          ? [
                              parse(
                                queryParams.startDate,
                                "yyyy-MM-dd",
                                new Date()
                              ),
                              parse(
                                queryParams.endDate,
                                "yyyy-MM-dd",
                                new Date()
                              ),
                            ]
                          : []
                      }
                      onClean={() => clearDateRange()}
                      onOk={(date) => filterByDateRange(date)}
                    />

                    <button
                      onClick={() => {
                        refetch();
                        setTransID([]);
                      }}
                      className="btn text-primary mx-3"
                    >
                      <CachedIcon />
                    </button>
                  </div>

                  <div className="d-flex gap-3">
                    <div className="" style={{ zIndex: 999 }}>
                      <Select
                        options={data?.warehouse || []}
                        classNamePrefix={"form-select"}
                        menuPosition="fixed"
                        menuPlacement="auto"
                        placeholder="Sort by warehouse"
                        isSearchable={true}
                        name="warehouse"
                        key={data?.warehouse}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            warehouse: selected?.source,
                          });
                        }}
                      />
                    </div>

                    <Button
                      disabled={isEmpty(data?.pendingPermits)}
                      onClick={() =>
                        handleSelectApproveAndProcessAll(selectedTransID)
                      }
                      variant="outline-primary"
                      className=" px-3"
                    >
                      Approve and Process All
                    </Button>
                    <Button
                      disabled={isEmpty(data?.pendingPermits)}
                      onClick={() => disapproveAll()}
                      variant="danger"
                      className="text-white px-3"
                    >
                      Disapprove All
                    </Button>

                    <Button
                      disabled={isEmpty(data?.pendingPermits)}
                      onClick={() => approveAll()}
                    >
                      Approve all
                    </Button>
                  </div>
                </header>

                <div className="px-md-4">
                  <Table
                    borderless
                    responsive
                    hover
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th />
                        <th>TransID</th>
                        <th>Total Value</th>
                        <th>Business Name</th>
                        <th>Sent By</th>
                        <th>Payment Status</th>
                        <th>Date</th>

                        <th>
                          {" "}
                          {!isEmpty(data?.pendingPermits) && (
                            <Form.Check
                              label="Select All"
                              checked={
                                data?.pendingPermits?.length ===
                                selectedTransID?.length
                              }
                              onChange={() =>
                                handleSelectAll(data?.pendingPermits)
                              }
                            />
                          )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.pendingPermits?.map((el, index) => (
                        <tr className="p-cursor" key={index}>
                          <td>
                            <Form.Check
                              checked={
                                !isEmpty(
                                  selectedTransID.find(
                                    (check) => check === el?.TransactionID
                                  )
                                )
                              }
                              onChange={() =>
                                handleItemCheck({ id: el?.TransactionID })
                              }
                            />
                          </td>
                          <td onClick={() => viewDetails(el)}>
                            {el.TransactionID}
                          </td>
                          <td onClick={() => viewDetails(el)}>
                            {currency(el.totalValue, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td onClick={() => viewDetails(el)}>
                            {el?.customer?.LastName === "Walk-In" && el?.ShipTo
                              ? `${el?.ShipTo} (Walk-In)`
                              : customerFullName(el.customer)}
                          </td>

                          <td onClick={() => viewDetails(el)}>{el.UserName}</td>
                          <td
                            onClick={() => changePaymentStatus(el)}
                            title="Edit Payment Status"
                          >
                            {el.paymentStatus || "..."}
                          </td>
                          <td onClick={() => viewDetails(el)}>
                            {" "}
                            {el.Date_Log
                              ? format(new Date(el.Date_Log), "dd MMM, yyyy")
                              : "..."}{" "}
                          </td>
                          <td className="position-relative">
                            <div className="d-flex gap-3">
                              {/*  <Link
                            to={`/approval/details/${el.TransactionID}`}
                            className="btn border bg-white text-primary d-flex align-items-center"
                          >
                            View Details
                          </Link> */}

                              <Button
                                className=""
                                variant="outline-primary"
                                onClick={async () =>
                                  await handleApproveAndProcess(
                                    el.TransactionID
                                  )
                                }
                              >
                                Approve and Process
                              </Button>
                              <Button
                                className="ga-btn"
                                onClick={() => approve(el)}
                              >
                                Approve
                              </Button>
                              <Button
                                className="ga-btn"
                                variant="outline-danger"
                                onClick={() => disapprove(el)}
                              >
                                Disapprove
                              </Button>

                              <Dropdown style={{ margin: 0 }}>
                                <Dropdown.Toggle
                                  variant=""
                                  className="bg-light-blue text-primary"
                                  bsPrefix="print more"
                                >
                                  <DotsHorizontalIcon />
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  popperConfig={{
                                    strategy: "fixed",
                                  }}
                                  renderOnMount
                                  className="dropdown-with-icons"
                                >
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() =>
                                      copyText(
                                        `${
                                          services.frontendUrl
                                        }/approval/details/${el.TransactionID}`
                                      )
                                    }
                                  >
                                    <LinkVariantIcon />
                                    Copy Link
                                  </Dropdown.Item>

                                  {isSalesManager || isAdmin || isCashier ? (
                                    <Dropdown.Item
                                      as="button"
                                      onClick={() =>
                                        setOrderTransactionID(el.TransactionID)
                                      }
                                    >
                                      <EyeOutlineIcon /> View Items
                                    </Dropdown.Item>
                                  ) : null}

                                  <Dropdown.Item as="div">
                                    <Link
                                      to={`/approval/details/${
                                        el.TransactionID
                                      }`}
                                    >
                                      <EditIcon /> Edit Items
                                    </Link>
                                  </Dropdown.Item>

                                  {el.UserName === "Store" && (
                                    <Dropdown.Item
                                      as="button"
                                      onClick={() => changePaymentStatus(el)}
                                    >
                                      <EditIcon /> Edit Payment Status
                                    </Dropdown.Item>
                                  )}

                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => deletePermit(el)}
                                  >
                                    <DeleteIcon /> Delete
                                  </Dropdown.Item>
                                  {/* 
                              <Dropdown.Item as="button" onClick={() => {}}>
                                <DropdownCheckIcon /> Appove
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                className="text-danger"
                                onClick={() => {}}
                              >
                                <DropdownCloseIcon /> Reject
                              </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {!isFetching && isSuccess && isEmpty(data?.pendingPermits) ? (
                    <NoTableItem queryParams={queryParams} />
                  ) : null}

                  {!isFetching && (
                    <div
                      className={
                        btnSummryActive
                          ? `summaryNew border-top open`
                          : `summaryNew border-top`
                      }
                    >
                      <div className="summaryHeader">
                        <h2>Summary</h2>
                        {btnSummryActive && (
                          <button onClick={() => close()} className="btn p-0">
                            <SummaryCloseIcon />
                          </button>
                        )}
                      </div>

                      <div className="gridCont">
                        <div className="gridChild gridChildBorderLeftBlue">
                          <div className="gridChildLeft">
                            <p className="gridChld1">
                              {currency(data?.TotalAmount, {
                                symbol: "",
                              }).format()}
                            </p>
                            <p className="gridChld2">Total Order Amt</p>
                          </div>

                          {/* <div className="gridChildRight">
                          <SummaryChartIconUp />
                          <p>5%</p>
                        </div> */}
                        </div>

                        {/* <div className="gridChild gridChildBorderLeftOrange">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data?.AmountPaid, {
                              symbol: "",
                            }).format()}
                          </p>
                          <p className="gridChld2">Total Payment Amt</p>
                        </div>

                        <div className="gridChildRight">
                          <SummaryChartIconUp />
                          <p>5%</p>
                        </div>
                      </div>

                      <div className="gridChild gridChildBorderLeftBrown">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(0, {
                              symbol: "",
                            }).format()}
                          </p>
                          <p className="gridChld2">Balance (LCY)</p>
                        </div>

                        <div className="gridChildRight">
                          <SummaryChartIconDown />
                          <p>5%</p>
                        </div>
                      </div>

                      <div className="gridChild gridChildBorderLeftDarkerGreen">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(0, {
                              symbol: "",
                            }).format()}
                          </p>
                          <p className="gridChld2">Reversed Invoice Amt</p>
                        </div>

                        <div className="gridChildRight">
                          <SummaryChartIconDown />
                          <p>5%</p>
                        </div>
                      </div>

                      <div className="gridChild gridChildBorderLeftDarkGreen">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(0, {
                              symbol: "",
                            }).format()}
                          </p>
                          <p className="gridChld2"> Reversed Payment Amt</p>
                        </div>

                        <div className="gridChildRight">
                          <SummaryChartIconUp />
                          <p>5%</p>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>

        <Outlet />

        {orderTransactionID && (
          <ViewOrdersModal
            show={orderTransactionID}
            onHide={() => setOrderTransactionID(false)}
            TransactionID={orderTransactionID}
            hasLockOptions={true}
          />
        )}

        <ModalLoader
          show={
            deletePermitMutation.isLoading || updatePermitsMutation.isLoading
          }
        />
      </main>
    </IsPrivileged>
  );
}
